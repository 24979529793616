import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '@/components/header';
import { Helmet } from 'react-helmet';
import './layout.css';
import { Container } from '@material-ui/core';
import TextButton from './textbutton';

const Layout: React.FC = ({ children }) => (
  <>
    <Header />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Roboto+Mono:wght@400;500&family=Rubik:wght@400;500;700&family=Varela+Round:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Container className="main-container">{children}</Container>
    <Container>
      <footer className="footer">
        <div>© ARR Analysis {new Date().getFullYear()}</div>
        <TextButton
          text="About"
          onClick={(event) => console.log(event)}
          footer
        />
        <TextButton
          text="Jobs"
          onClick={(event) => console.log(event)}
          footer
        />
        <TextButton
          text="Security"
          onClick={(event) => console.log(event)}
          footer
        />
        <TextButton
          text="Privacy Policy"
          onClick={(event) => console.log(event)}
          footer
        />
      </footer>
    </Container>
  </>
);

export default Layout;
