/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { PageProps } from 'gatsby';

import SEO from '@/components/seo';
import Layout from '@/components/layout';
import ChartImage from '@/components/chartimage';

const Home: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        fontWeight: 500,
        fontSize: `56px`,
        letterSpacing: `-1.92px`,
        marginBottom: `1.5rem`,
      }}
    >
      Simple and scalable financial analytics
    </div>
    <div
      className="font-inter"
      style={{ fontSize: `20px`, marginBottom: `1.5rem` }}
    >
      Upload company data, conduct analysis, visualize results
    </div>
    <div
      className="get-started-btn"
      onClick={() => {
        window.location.href = `https://app.arranalysis.com`;
      }}
    >
      Get Started
    </div>
    <ChartImage />
  </Layout>
);

export default Home;
