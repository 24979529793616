/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, Container, Toolbar } from '@material-ui/core';
import React from 'react';
import './textbutton.css';

interface Props {
  footer: boolean;
  text: string;
  onClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const TextButton: React.FC<Props> = ({ footer, text, onClick }) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={footer ? `text-button--footer` : `text-button`}
    onClick={onClick}
  >
    {text}
  </div>
);

export default TextButton;
