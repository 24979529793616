/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AppBar, Container, Toolbar } from '@material-ui/core';
import React from 'react';
import './header.css';
import TextButton from './textbutton';

const Header: React.FC = () => (
  <AppBar className="header" elevation={0} color="inherit" position="static">
    <Container>
      <Toolbar className="toolbar" disableGutters>
        <div className="logo">ARR</div>
        <div className="header__center-btns">
          <div className="text-button-group">
            <TextButton
              footer={false}
              text="Product"
              onClick={(event) => console.log(`event`, event)}
            />
            <TextButton
              footer={false}
              text="Pricing"
              onClick={(event) => console.log(`event`, event)}
            />
            <TextButton
              footer={false}
              text="Docs"
              onClick={(event) => console.log(`event`, event)}
            />
            <TextButton
              footer={false}
              text="Contact"
              onClick={(event) => console.log(`event`, event)}
            />
          </div>
        </div>
        <div
          className="get-started-btn get-started-btn--header"
          onClick={() => {
            window.location.href = `https://app.arranalysis.com`;
          }}
        >
          Get Started
        </div>
      </Toolbar>
    </Container>
  </AppBar>
);

export default Header;
